import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { GenericModal } from '../../../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../../../components/GenericModal/GenericModalHeader';
import { Alert, Button, ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  when: boolean
}

export const CmsNavigationPrompt = ({ when }: Props) => {
  return (
    <NavigationPrompt
      when={when}
    >
      {({ isActive, onConfirm, onCancel }) => {
        if (isActive) {
          return (
            <CmsNavigationPromptModal
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )

        }
      }
      }
    </NavigationPrompt>
  )
}

interface ModalProps {
  onConfirm: () => void,
  onCancel: () => void,
}

export const CmsNavigationPromptModal = ({ onConfirm, onCancel }: ModalProps) => {
  return (
    <GenericModal isOpen={true} >
      <GenericModalHeader
        // onClose={onCancel}
        title={`Attention`}
        icon={'/assets/icons/16/failure_alert.svg'}
      />
      <ModalBody>
        <Alert color={'warning'} style={{ textAlign: 'center' }}>It seems, you have unsaved work!<br /> <b>Are you sure you want to leave without saving?</b></Alert>
      </ModalBody>
      <ModalFooter>
        <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={onCancel}>No, let me save</Button>
        <Button style={{ flex: 1 }} size={'lg'} color="primary" onClick={onConfirm}>Yes, no need to save!</Button>
      </ModalFooter>
    </GenericModal>
  )
}
